<template>
  <div>
    <btn-back class="mb-4" :to="{ name: 'news-index' }" />

    <template v-if="news">
      <v-sheet class="pa-4" elevation="3">
        <news-form :news="news" :loading="updating" @change-field="changeField" @submit="updateNews" />
      </v-sheet>
    </template>

    <h2 v-else-if="!loading" class="mb-4">Новость по slug "{{ slug }}" не найдена</h2>
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";
import { editorForm } from "~/mixins/multi-editor/editor-form";

import NewsForm from "~/components/news/form";

export default {
  mixins: [editorForm],
  data() {
    return {
      news: null,

      loading: true,
      updating: false
    };
  },
  created() {
    this.fetchNewsBySlug();
  },
  computed: {
    slug() {
      return this.$route.params.slug;
    },
    slugWithDate() {
      return `${this.$route.params.year}/${this.$route.params.month}/${this.$route.params.day}/${this.$route.params.slug}`;
    }
  },
  methods: {
    async fetchNewsBySlug() {
      this.loading = true;

      try {
        this.news = await this.$axios.$get(`news/${this.slugWithDate}`);
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error(`Новость по slug: "${this.slug}" не найдена ${html}`);
      } finally {
        this.loading = false;
      }
    },
    async updateNews() {
      if (this.updating) return;

      this.updating = true;

      try {
        this.news = await this.$axios.$post(`news/${this.slugWithDate}`, this.news);

        this.$snackbar.success("Обновлено");
      } catch (e) {
        const html = new ApiValidationError(e, Object.keys(this.news)).toHtml();

        this.$snackbar.error("Не обновлено" + html);
      } finally {
        this.updating = false;
      }
    },

    changeField(payload) {
      this.commitChange(this.news, payload);
    }
  },
  components: {
    NewsForm
  }
};
</script>
